import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal, Spin } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { CREATE_LIBRARY_DOCUMENT, CREATE_REPORT_TEMPLATE, CREATE_LIBRARY_DOCUMENT_VERSION } from "../../../constants/mutations.js";
import { handleMutation, FILE_UPLOAD_ERROR_MSG } from "../../../utils/errorHandling.js";
import { DocumentUploadModal } from "../../common/DocumentUploadModal/index.js";
import { LIBRARY_FILE_EXTENTION_ARRAY } from "../../../utils/index.js";

export const UploadLibraryDocument = ({
    afterUpload,
    disabled,
    currentFolderId,
    baseFolderName,
    contractTypeId,
    isReportTemplate,
    reportTemplateId,
    reportTemplateVersion,
    latestVersionStartDate,
    reportTemplateVersionCount

}) => {

    const [uploadLibraryModal, setUploadLibraryModal] = useState(false);
    const [createLibraryFiles, setCreateLibraryFiles] = useState({});
    const [startDate, setStartDate] = useState("");
    const [createLibraryDocument, createLibraryDocumentProgress] = useMutation(CREATE_LIBRARY_DOCUMENT);
    const [createLibraryDocumentVersion, createLibraryDocumentVersionProgress] = useMutation(CREATE_LIBRARY_DOCUMENT_VERSION);
    const [createReportTemplate, createReportTemplateProgress] = useMutation(CREATE_REPORT_TEMPLATE);

    const resetState = () => {
        setUploadLibraryModal(false);
        setCreateLibraryFiles({});
    };

    const isLoading = createReportTemplateProgress.loading || createLibraryDocumentProgress.loading || createLibraryDocumentVersionProgress.loading;

    // if any of these conditions is true, we want to disable save button :: 
    //  - no file selected for upload 
    //  - size of file is 0 
    //  - an upload is currently underway (i.e., isLoading is true)
    //  - upload is for a report template and no start date has been set 
    const disableSave = [
        Object.keys(createLibraryFiles).length === 0,
        createLibraryFiles.size === 0,
        isLoading,
        isReportTemplate && !startDate
    ].some(condition => condition === true);

    return (
        <>
            <Modal
                title="Upload Library Document"
                destroyOnClose={true}
                maskClosable={false}
                closable={false}
                open={uploadLibraryModal}
                okText="Save"
                onCancel={() => {
                    resetState();
                }}
                okButtonProps={{ disabled: disableSave }}
                cancelButtonProps={{ disabled: isLoading }}
                onOk={async (e) => {
                    try {
                        e.preventDefault();
                        if (isReportTemplate && !reportTemplateVersion) {
                            await handleMutation(
                                createReportTemplate({
                                    variables: {
                                        reportTemplateInput: {
                                            name: createLibraryFiles?.name,
                                            description: "",
                                            libraryDocumentInput: {
                                                documentName: createLibraryFiles.name,
                                                fileSize: (createLibraryFiles.size).toString(),
                                                libraryFolderId: currentFolderId,
                                                contractTypeId,
                                                baseFolderName,
                                                file: createLibraryFiles.originFileObj,
                                                startDate: startDate
                                            }
                                        }
                                    }
                                })
                            );
                        } else if (isReportTemplate && reportTemplateVersion) {
                            await handleMutation(
                                createLibraryDocumentVersion({
                                    variables: {
                                        libraryDocumentInput: {
                                            documentName: createLibraryFiles.name,
                                            fileSize: (createLibraryFiles.size).toString(),
                                            libraryFolderId: currentFolderId,
                                            contractTypeId,
                                            startDate: startDate,
                                            reportTemplateId: reportTemplateId,
                                            file: createLibraryFiles.originFileObj
                                        }
                                    }
                                })
                            );

                        }
                        else {
                            await handleMutation(
                                createLibraryDocument({
                                    variables: {
                                        libraryDocumentInput: {
                                            documentName: createLibraryFiles.name,
                                            fileSize: (createLibraryFiles.size).toString(),
                                            libraryFolderId: currentFolderId,
                                            contractTypeId,
                                            baseFolderName,
                                            file: createLibraryFiles.originFileObj
                                        }
                                    }
                                })
                            );
                        }
                        if (typeof afterUpload === "function") {
                            afterUpload();
                        }
                    } finally {
                        resetState();
                    }
                }}
            >
                <Spin size="large" spinning={isLoading}>
                    <DocumentUploadModal
                        uploadFileMetaData={createLibraryFiles}
                        setUploadFileMetaData={setCreateLibraryFiles}
                        maxDocumentCount={1}
                        acceptingFileTypeList={LIBRARY_FILE_EXTENTION_ARRAY}
                        isReportTemplate={isReportTemplate}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        latestVersionStartDate={latestVersionStartDate}
                        reportTemplateVersionCount={reportTemplateVersionCount}
                        reportTemplateVersion={reportTemplateVersion}
                    />
                </Spin>
                {Object.keys(createLibraryFiles).length != 0 && createLibraryFiles.size === 0 && (
                    <span style={{
                        marginTop: "10px",
                        color: "red",
                        fontStyle: "italic"
                    }}>
                        <CloseCircleTwoTone twoToneColor="red" /> {FILE_UPLOAD_ERROR_MSG}
                    </span>
                )}
            </Modal>
            <Button
                type="primary"
                size="small"
                disabled={disabled} 
                onClick={() => {
                    setUploadLibraryModal(true);
                }}
            >
                Upload File
            </Button>
        </>
    );
};