import React, { useState, useContext } from "react";
import { Modal, Radio } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { Table } from "../common/Table/index.js";
import { S3UploadStatus } from "../common/S3UploadStatus/index.js";
import { UploadLibraryDocument } from "../common/UploadLibraryDocument/index.js";
import { DateableDetail } from "../common/DateableDetail/index.js";
import { isStartDateDisabled } from "../../utils/dateFunctions.js";
import { MODIFY_LIBRARY_DOCUMENT } from "../../constants/mutations.js";
import { useMutation } from "@apollo/client";
import { LibraryDocumentActions } from "../common/LibraryDocumentActions/index.js";
import { handleMutation } from "../../utils/errorHandling.js";

export const ReportTemplatesTable = ({
    loading,
    reportTemplate,
    currentFolderId,
    baseFolderName,
    contractTypeId,
    refetch
}) => {
    const [activeToggle, setActiveToggle] = useState(true);
    const [open, setOpen] = useState(false); 
    const [selected, setSelected] = useState({});
    const [startDate, setStartDate] = useState("");
    const {
        userPermUploadnModifyLibDoc,
        userPermEditLibraryDocuments 
    } = useContext(UserContext);
    const [modifyLibraryDocument] = useMutation(MODIFY_LIBRARY_DOCUMENT);

    const reportTemplateList = reportTemplate.versions.filter(({ reportTemplateStatus }) => {
        return activeToggle 
            ? reportTemplateStatus !== "Inactive" 
            : reportTemplateStatus === "Inactive";
    });
    const canUploadDocuments = userPermUploadnModifyLibDoc;
    const reportListCount = reportTemplate.versions.length;

    const openEditModal = () => setOpen(true);
    const closeEditModal = () => {
        setOpen(false);
        setStartDate("");
    };

    const rightAlignedRadioButtons =
        <Radio.Group
            defaultValue={activeToggle}
            value={activeToggle}
            onChange={(e) => {
                setActiveToggle(e.target.value);
            }}
            buttonStyle="solid"
        >
            <Radio.Button value={true} >Active </Radio.Button>
            <Radio.Button value={false}>Inactive </Radio.Button>
        </Radio.Group>;

    const reportTemplateTable = (
        <Table
            loading={loading}
            key="reportTemplateTable"
            size="middle"
            pagination={false}
            rowKey="name"
            columns={[
                {
                    title: "Name",
                    key: "name",
                    dataIndex: "name",
                    width: "20%"
                },
                {
                    key: "startDate",
                    title: "Effective Date",
                    dataIndex: "startDate",
                    width: "15%",
                    render: (startDate, { endDate }) => {
                        return <>{startDate} {endDate && <span> - {endDate}</span>}</>;
                    }
                },
                {
                    key: "versionNumber",
                    title: "Version",
                    dataIndex: "versionNumber",
                    width: "10%"
                },
                {
                    key: "S3UploadStatus",
                    title: "Upload Status",
                    dataIndex: "S3UploadStatus",
                    width: "10%",
                    render: S3UploadStatus
                },
                {
                    title: "Template Status",
                    dataIndex: "reportTemplateStatus",
                    key: "reportTemplateStatus",
                    width: "10%"
                },
                {
                    title: "Actions",
                    key: "actions",
                    width: "10%",
                    render: (document) => {
                        const userCanUpload = userPermUploadnModifyLibDoc;
                        const userCanEdit = userPermEditLibraryDocuments && reportTemplate?.latestVersion.versionNumber === document.versionNumber ;
                        const handleEdit = () => {
                            setSelected(document);
                            openEditModal();
                        };
                        return <LibraryDocumentActions 
                            editDocument={handleEdit}
                            record={{
                                id: document.libraryDocumentId,
                                ...document
                            }}
                            userCanEdit={userCanEdit}
                            userCanUpload={userCanUpload}
                            libraryFolderId={currentFolderId}
                            reportUpdatedDocument={() => refetch()}
                            onReuploadFailure={refetch}
                        />;
                    }
                }]}
            dataSource={reportTemplateList}
        />
    );
    const latestVersionStartDate = selected?.startDate;
    return (
        <>
            <div className="main-content-margin-top">
                <div style={{
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    float: "inline-end"
                }}>

                    <div style={{
                        float: "left"
                    }}>
                        <div style={{ display: "flex" }}>
                            <div><span style={{
                                fontSize: "16px",
                                fontWeight: "600"
                            }}>Report Templates</span></div>
                            <div style={{ marginLeft: "16px" }}>
                                {canUploadDocuments
                                    ? <UploadLibraryDocument
                                        afterUpload={() => {
                                            refetch();
                                        }}
                                        reportTemplateId={reportTemplate?.id}
                                        currentFolderId={currentFolderId}
                                        baseFolderName={baseFolderName}
                                        contractTypeId={contractTypeId}
                                        isReportTemplate={true}
                                        reportTemplateVersion={true}
                                        latestVersionStartDate={reportTemplate.latestVersion?.startDate}
                                        reportTemplateVersionCount={reportListCount}
                                    />
                                    : null
                                }
                            </div>

                        </div>
                    </div>
                    <div style={{
                        float: "right"
                    }}>
                        {rightAlignedRadioButtons}
                    </div>
                </div>
                {reportTemplateTable}
            </div>

            <Modal
                title={"Enter New Effective Date"}
                open={open}
                style={{ minWidth: 100 }}
                okText={"Save"}
                okButtonProps={{disabled: !startDate}}
                cancelText={"Cancel"}
                onOk={async () =>{
                    await handleMutation(
                        modifyLibraryDocument({
                            variables: {
                                id: selected?.libraryDocumentId,
                                startDate
                            }})
                    );
                    refetch();
                    closeEditModal();
                }}
                onCancel={closeEditModal}
                destroyOnClose={true}
            >
                <div>
                    <p><b>Name:</b> {selected?.name}</p>
                    <p><b>Version:</b> {selected?.versionNumber}</p>
                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                        <div style={{ fontWeight: 600 }}>Effective Date :</div>
                        <div>
                            <DateableDetail
                                key="startDate"
                                value={startDate}
                                disabledDate={(date) => isStartDateDisabled(date, latestVersionStartDate)}
                                onValueUpdated={(value) => {
                                    setStartDate(value);
                                }}
                                isReportTemplate

                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>

    );
};